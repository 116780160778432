import React from "react";
import { useLocation } from "react-router-dom";

import ResponsiveDrawer from "../Drawer";

function PhoneDetails(props) {
  const location = useLocation();

  return (
    <div>
      <ResponsiveDrawer
        id={location.state.data.androidId}
        fireData={location.state.fireData}
      />
    </div>
  );
}

export default PhoneDetails;
