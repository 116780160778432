import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Container from "@mui/material/Container";

export default function DataTable(props) {

  const columns = [
    { field: 'date', headerName: 'Date', flex:0.3, width: 70,headerClassName: 'header', type: 'date',  align: "left", headerAlign: "left" },

     
    { field: 'message', headerName: 'Message', flex:0.3,width: 130,headerClassName: 'header', type: 'string', align: "left", headerAlign: "left" },
    { field: 'number', headerName: 'Number', flex:0.2,width: 130,headerClassName: 'header', type: 'string', align: "left", headerAlign: "left" }, 
    { field: 'type', headerName: 'Type', flex:0.2,width: 130,headerClassName: 'header', type: 'string', align: "left", headerAlign: "left",},

  ];
  
  const newRows = [];
  // const newRows = props.data;
  props.data.forEach((element) => {
    newRows.push(element);
  });


  return (
    <Container maxWidth="lg">
    <div style={{ height: 630, width: '100%' }}>
          <DataGrid
            rows={newRows}
            columns={columns}
            getRowHeight={() => 'auto'}
            pageSize={10}
            rowsPerPageOptions={[10]}
            getRowId={(row: any) =>  row.date+row.message}
            getRowClassName={(params) => `super-app-theme--${params.row.status}`}
            sx={{
              '& .super-app-theme--pending': {
                backgroundColor: '#ff0000',
                color: '#fff',
              }, 
              '& .super-app-theme--completed': {
                backgroundColor: '#00ff00',
                color: '#fff',
              },
              '& .super-app-theme--cancelled': {
                backgroundColor: '#0000ff',
                color: '#fff',
              },
              fontFamily: 'Montserrat',
              fontSize: '14px',     
              fontWeight: '550',
              color: "black",
              backgroundColor: "#FAF9F6",
            }}
          />
        </div>
    </Container>
    
  );
}