import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from '@mui/material/Container';

import "./style.css";

const bull = (
  <Box
    component="span"
    sx={{
      display: "inline-block",
      mx: "2px",
      transform: "scale(0.8)",
    }}
  >
    •
  </Box>
);

export default function BasicCard(props) {
  return (
    
    <Card sx={{ minWidth: 275, boxShadow: "none" }}>
      {/* <Container maxWidth="sm"> */}

      <CardContent
        sx={{
          backgroundColor: "#FAF9F6",
        }}
      >
        <Typography
          variant="h6"
          align="center"
          gutterBottom
          sx={{
            fontFamily: "Montserrat",
            fontWeight: "600",
            color: "black",
          }}
        >
          {props.heading}
        </Typography>
        <Typography
          variant="h4"
          align="center"
          component="div"
          sx={{
            fontFamily: "Montserrat",
            fontWeight: "100",
            color: "black",
          }}
        >
          {props.deviceCount}
        </Typography>
      </CardContent>
      {/* </Container> */}
    </Card>
  );
}
