import { useState } from "react";

import { close, menu, mobisec_logo } from "../assets";
import { navLinks } from "../constants";


const Navbar = (props) => {
  const [active, setActive] = useState("Home");
  const [toggle, setToggle] = useState(false);

  // const navLinks = props.isAuthenticated ? navLinksAuthenticated : navLinksNotAuthenticated;

  return (
    <nav className="w-full flex py-6 justify-between items-center navbar">
      <img src={mobisec_logo} alt="mobisec" className="w-[100px] h-[32px]" />

      <ul className="list-none sm:flex hidden justify-end items-center flex-1">
        {navLinks.map((nav, index) => (
          <li
            key={nav.id}
            className={`font-poppins font-normal cursor-pointer text-[16px] ${
              active === nav.title ? "text-white" : "text-dimWhite"
            } ${index === navLinks.length - 1 ? "mr-0" : "mr-10"}`}
            onClick={() => setActive(nav.title)}
          >
            
            {/* {
              props.isAuthenticated && nav.id === "username" ? <a href={`/home`}>Welcome, {props.displayName}</a> : props.isAuthenticated && nav.id === "signout" ? <a href="/" onClick={() => auth.signOut()}>Sign Out</a> : nav.id === "home" ? <a href="#home">Home</a>: nav.id === "demo"? <a href="/signin">Demo</a> : <a href={`/${nav.id}`}>{nav.title}</a>
            } */}
            {
              nav.id === "home" ? <a href="#home">Home</a>: nav.id === "demo"? <a href="/signin">Demo</a> : <a href={`/${nav.id}`}>{nav.title}</a>
            }

          </li>
          
        ))}
      </ul>

      <div className="sm:hidden flex flex-1 justify-end items-center">
        <img
          src={toggle ? close : menu}
          alt="menu"
          className="w-[28px] h-[28px] object-contain"
          onClick={() => setToggle(!toggle)}
        />

        <div
          className={`${
            !toggle ? "hidden" : "flex"
          } p-6 bg-black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar`}
        >
          <ul className="list-none flex justify-end items-start flex-1 flex-col">
            {navLinks.map((nav, index) => (
              <li
                key={nav.id}
                className={`font-poppins font-medium cursor-pointer text-[16px] ${
                  active === nav.title ? "text-white" : "text-dimWhite"
                } ${index === navLinks.length - 1 ? "mb-0" : "mb-4"}`}
                onClick={() => setActive(nav.title)}
              >
                <a href={`#${nav.id}`}>{nav.title}</a>
              </li>
            ))}
          </ul>
          
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
