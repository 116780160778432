import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CallIcon from "@mui/icons-material/Call";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import Button from "@mui/material/Button";
import { auth } from "../../firebase-config.js";


import CallLog from "../callLog";
import Contacts from "../contacts";
import Location from "../location";
import MobileNetwork from "../mobileNetwork";
import SMS from "../sms";
import FileList from "../fileList";
// import Files from "../files";

import ContactsIcon from '@mui/icons-material/Contacts';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NetworkCellIcon from '@mui/icons-material/NetworkCell';
import SmsIcon from '@mui/icons-material/Sms';

import "./style.css";

const drawerWidth = 240;

function ResponsiveDrawer(props) {

  document.body.style = 'background: #F8F5FA;';
  const id = props.id;
  const fireData = props.fireData;

  const jsonData = JSON.stringify(fireData);
  const data = JSON.parse(jsonData);

  // array of objects for the rendering of data in the drawer
  const callLog = data[id].callLog;
  const contact = data[id].contact;
  const location = data[id].location;
  const mobileNetwork = data[id].mobileNetwork;
  const sms = data[id].sms;
  const fileList = data[id].fileList;
  const files = data[id].files;

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [component, setComponent] = React.useState("Call Log");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      
      <img src="./mobisec-favicon.png" alt="Logo" border="0" 
      height="80px" width="80px" style={{
        margin: "auto",
        display: "block",
      }}
      />
      <Toolbar />
      <Divider />
      <List>
        {[
          "Call Log",
          "Contacts",
          "File List",
          "Location",
          "Mobile Network",
          "SMS",
        ].map((text, index) => (
          <ListItem
            button
            onClick={() => setComponent(text)}
            key={text}
            disablePadding
            selected={component === text}
          >
            <ListItemButton>
              <ListItemIcon>
                {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                {
                  index === 0 ? <CallIcon /> :index === 1? <ContactsIcon />:
                  index === 2? <AttachFileIcon />:
                  index === 3? <FileCopyIcon />:
                  index === 4? <LocationOnIcon />: 
                  index === 5? <NetworkCellIcon />:
                  index === 6? <SmsIcon />: null

                }
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className="bg-image">
      <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Breadcrumbs aria-label="breadcrumb" sx={{
            flexGrow: 1,
          }}>
            <Link underline="hover" color="inherit" href="/home">
              <HomeIcon />
            </Link>
            <Typography color="text.primary" style={{
              fontWeight: "400",
            }}>{props.id}</Typography>
          </Breadcrumbs>
          <Button variant="contained" size="small" sx={{
              // top: "10px", 
              left: "5px",
            }}><a href="/" onClick={() => auth.signOut()}>Sign Out</a></Button>
        </Toolbar>
      </AppBar>
      
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />

        {component === "Call Log" ? (
          <CallLog callLog={callLog} id={id}/>
        ) : component === "Contacts" ? (
          <Contacts contact={contact} id={id}/>
        ) : component === "File List" ? (
          <FileList fileList={fileList} id={id}/>
        ) : component === "Location" ? (
          <Location location={location} id={id}/>
        ) : component === "Mobile Network" ? (
          <MobileNetwork mobileNetwork={mobileNetwork} id={id}/>
        ) : component === "SMS" ? (
          <SMS sms={sms} id={id}/>
        ) : null}
      </Box>
    </Box>
    </div>
    
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
