import {React, useEffect, useState} from "react";

import DenseTable from "./smsTable";
import Container from "@mui/material/Container";
import { motion } from "framer-motion";

import FirebaseData from "../../firebaseData";

import smsSVG from "./sms.svg";

function SMS(props) {

  const firebaseData = FirebaseData();
  const id=props.id;

  const [newData, setNewData] = useState();

  useEffect(() => {
    if (firebaseData) {
      setNewData(firebaseData[id]?.sms);
    }
  }, [firebaseData, id]);

  const testData=[];

  for(const date in newData){
    for(const call in newData[date]){
      testData.push(newData[date][call]);
    }
  }


  const sms = props.sms;

  const data = [];

  for (const date in sms) {
    for(const call in sms[date]){
        data.push(sms[date][call]);
    }

  }


  return (
    <div>
      <Container>
      <motion.div
          initial={{ opacity: 0.5, x: 1080, y: -40 }}
          animate={{ opacity: 1, x: 1080, y: -40 }}
          transition={{ duration: 2.5 }}
        >
          <img
            src={smsSVG}
            className="phone"
            alt="phone"
            height="130px"
            width="130px"
            style={{
              transform: "rotate(20deg)",
              position: "absolute",
            }}
          />
        </motion.div>
        <DenseTable data={testData} />
      </Container>
    </div>
  );
}

export default SMS;
