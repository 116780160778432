import {React, useEffect, useState} from "react";

import DenseTable from "./locationTable";
import Container from "@mui/material/Container";
import { motion } from "framer-motion";

import locationSVG from "./location.svg";

import FirebaseData from "../../firebaseData";

function Location(props) {

  const firebaseData = FirebaseData();
  const id=props.id;

  const [newData, setNewData] = useState();

  useEffect(() => {
    if (firebaseData) {
      setNewData(firebaseData[id]?.location);
    }
  }, [firebaseData, id]);

  const testData=[];

  for(const date in newData){
    testData.push(newData[date]);
  }


  // const location = props.location;

  // const data = [];

  // for (const date in location) {
  //   data.push(location[date]);  
  // }

  return (
    <div>
      <Container>
      <motion.div
          initial={{ opacity: 0.5, x: 1090, y: -40 }}
          animate={{ opacity: 1, x: 1090, y: -40 }}
          transition={{ duration: 2.5 }}
        >
          <img
            src={locationSVG}
            className="phone"
            alt="phone"
            height="120px"
            width="120px"
            style={{
              transform: "rotate(20deg)",
              position: "absolute",
            }}
          />
        </motion.div>
        <DenseTable data={testData} />
      </Container>
    </div>
  );
}

export default Location;
