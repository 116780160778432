import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Container from "@mui/material/Container";

export default function DataTable(props) {

  const columns = [
    {
      field: "ip",
      headerName: "IP",
      flex: 0.2,
      width: 70,
      headerClassName: "header",
      type: "string",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "mac",
      headerName: "MAC",
      flex: 0.2,
      width: 130,
      headerClassName: "header",
      type: "number",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "make",
      headerName: "Make",
      flex: 0.2,
      width: 130,
      headerClassName: "header",
    },
    {
      field: "model",
      headerName: "Model",
      flex: 0.2,
      width: 130,
      headerClassName: "header",
    },
    {
      field: "networkOperator",
      headerName: "Network Operator",
      flex: 0.2,
      width: 130,
      headerClassName: "header",
    },
    {
      field: "networkCountry",
      headerName: "Network Country",
      flex: 0.2,
      width: 130,
      headerClassName: "header",
    },
    {
      field: "ssid",
      headerName: "SSID",
      flex: 0.2,
      width: 130,
      headerClassName: "header",
    },
  ];
  

  const newRows = [];
  props.data.forEach((element) => {
    element.sno=newRows.length+1;
    newRows.push(element);
  });


  return (
    <Container maxWidth="lg">
    <div style={{ height: 630, width: '100%' }}>
          <DataGrid
            rows={newRows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            getRowId={(row: any) =>  row.model+row.make+row.sno }
            getRowClassName={(params) => `super-app-theme--${params.row.status}`}
            sx={{
              '& .super-app-theme--pending': {
                backgroundColor: '#ff0000',
                color: '#fff',
              }, 
              '& .super-app-theme--completed': {
                backgroundColor: '#00ff00',
                color: '#fff',
              },
              '& .super-app-theme--cancelled': {
                backgroundColor: '#0000ff',
                color: '#fff',
              },
              fontFamily: 'Montserrat',
              fontSize: '14px',     
              fontWeight: '550',
              color: "black",
              backgroundColor: "#FAF9F6",
            }}
          />
        </div>
    </Container>
    
  );
}