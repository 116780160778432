import {React, useEffect, useState} from "react";

import DenseTable from "./contactsTable";
import Container from "@mui/material/Container";
import { motion } from "framer-motion";

import FirebaseData from "../../firebaseData";

import contactSVG from "./contact.svg";

function Contacts(props) {

  const firebaseData = FirebaseData();
  const id=props.id;

  const [newData, setNewData] = useState();

  useEffect(() => {
    if (firebaseData) {
      setNewData(firebaseData[id]?.contact);
    }
  }, [firebaseData, id]);

  const testData=[];

  for(const date in newData){
    for(const call in newData[date]){
      testData.push(newData[date][call]);
    }
  }

  console.log(testData);


  // const contacts = props.contact;

  // const data = [];

  // for (const date in contacts) {
  //   for (const call in contacts[date]) {
  //     data.push(contacts[date][call]);
  //   }
  // }
  // // console.log(data);
  return (
    <div>
      <Container>
      <motion.div
          initial={{ opacity: 0.5, x: 1080, y: -40 }}
          animate={{ opacity: 1, x: 1080, y: -40 }}
          transition={{ duration: 2.5 }}
        >
          <img
            src={contactSVG}
            className="phone"
            alt="phone"
            height="130px"
            width="130px"
            style={{
              transform: "rotate(20deg)",
              position: "absolute",
            }}
          />
        </motion.div>
        <DenseTable data={testData} />
      </Container>
    </div>
  );
}

export default Contacts;
