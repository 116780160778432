import "./App.css";

import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import Landing from "./components/Landing";
import PhoneDetails from "./components/PhoneDetails";
import Home from "./components/Home/App.jsx";
import Signup from "./components/Signup";
import SignIn from "./components/Signin";
import ForgotPassword from "./components/ForgotPassword";
import MapView from "./components/MapView";

import { auth } from "./firebase-config.js";
import { useEffect } from "react";
import FirebaseData from "./firebaseData";


function App() {
  // getting the user data from firebase
  const firebaseData = FirebaseData();

  const [displayName, setDisplayName] = React.useState("");
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [role, setRole] = React.useState("");

  useEffect(() => {
    if (firebaseData) {
      auth.onAuthStateChanged((user) => {
        if (user) {
          // User is signed in
          // ...
          setIsAuthenticated(true);
          setDisplayName(user.displayName);
          setRole(firebaseData.users?.[user.uid]?.role);
        } else {
          // User is signed out
          // ...
          setIsAuthenticated(false);
          setDisplayName("");
          setRole("");
        }
      });
    }
  }, [firebaseData]);

  return (
    <Routes>
      <Route
        path="/"
        exact
        element={
          <Home
            isAuthenticated={isAuthenticated}
            displayName={displayName}
            role={role}
          />
        }
      />
      <Route path="/signup" element={<Signup />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />

      {role === "admin" && (
        <Route
          path="/home"
          element={
            isAuthenticated && role === "admin" ? (
              <Landing displayName={displayName}/>
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
      )}

      {role === "admin" && (
        <Route
          path="/details"
          element={
            isAuthenticated && role === "admin" ? (
              <PhoneDetails />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
      )}

      {role === "admin" && (
        <Route
          path="/map-view"
          element={
            isAuthenticated && role === "admin" ? (
              <MapView />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
      )}

      <Route path="/" element={<Navigate replace to="/" />} />
    </Routes>
  );
}

export default App;
