import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Container from "@mui/material/Container";
// import Button from "@mui/material/Button";
// import { useNavigate } from "react-router-dom";

export default function DataTable(props) {

  // const navigate = useNavigate();

  const columns = [
    { field: 'alt', headerName: 'Altitude', flex:0.2, width: 70,headerClassName: 'header', type: 'string', align: "left", headerAlign: "left" },
    { field: 'lat', headerName: 'Latitude', flex:0.2,width: 130,headerClassName: 'header', type: 'string', align: "left", headerAlign: "left" },
    { field: 'long', headerName: 'Longitude', flex:0.2,width: 130,headerClassName: 'header', type: 'string', align: "left", headerAlign: "left" },
    { field: 'timeStamp', headerName: 'Time Stamp', flex:0.2,width: 130,headerClassName: 'header', type: 'date', align: "left", headerAlign: "left" },
    // {
    //   field: "action",
    //   headerName: "Map View",
    //   headerClassName: "header",
    //   sortable: false,
    //   renderCell: (params) => {
    //     const onClick = (e) => {
    //       e.stopPropagation(); // don't select this row after clicking

    //       const api: GridApi = params.api;
    //       const thisRow: Record<string, GridCellValue> = {};

    //       api
    //         .getAllColumns()
    //         .filter((c) => c.field !== "__check__" && !!c)
    //         .forEach(
    //           (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
    //         );

    //       return navigate("/map-view", {
    //         state: { data: thisRow},
    //       });
    //     };

    //     return (
    //       <Button
    //         variant="contained"
    //         style={{ maxHeight: "30px", minWidth: "30px", minHeight: "30px" }}
    //         onClick={onClick}
    //       >
    //         View
    //       </Button>
    //     );
    //   },
    // },
  ];
  
  const newRows = [];
  props.data.forEach((element) => {
    newRows.push(element);
  });


  return (
    <Container maxWidth="lg">
    <div style={{ height: 630, width: '100%' }}>
          <DataGrid
            rows={newRows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            getRowId={(row: any) =>  row.timeStamp+row.lat}
            getRowClassName={(params) => `super-app-theme--${params.row.status}`}
            sx={{
              '& .super-app-theme--pending': {
                backgroundColor: '#ff0000',
                color: '#fff',
              }, 
              '& .super-app-theme--completed': {
                backgroundColor: '#00ff00',
                color: '#fff',
              },
              '& .super-app-theme--cancelled': {
                backgroundColor: '#0000ff',
                color: '#fff',
              },
              fontFamily: 'Montserrat',
              fontSize: '14px',     
              fontWeight: '550',
              color: "black",
              backgroundColor: "#FAF9F6",
            }}
          />
        </div>
    </Container>
    
  );
}