import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../Table";
import BasicCard from "../BasicCard";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import { auth } from "../../firebase-config.js";

import Avatar from "@mui/material/Avatar";
import "./style.css";

import { motion } from "framer-motion";
import computerSVG from "./computer.svg";
import computer2SVG from "./computer2.svg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import FirebaseData from "../../firebaseData";

function Landing(props) {
  let firebaseData = FirebaseData();

  const [selectedRow, setSelectedRow] = useState(null);
  const navigate = useNavigate();

  // rows and columns for the table
  const rows = [];

  for (const androidId in firebaseData) {
    if (androidId !== "users") {
      rows.push({ id: rows.length + 1, androidId: androidId });
    }
  }

  const columns = [
    {
      field: "id",
      headerName: "S. No.",
      flex: 0.2,
      width: 70,
      headerClassName: "header",
    },
    {
      field: "androidId",
      headerName: "Android ID",
      flex: 0.8,
      width: 130,
      headerClassName: "header",
      editable: true,
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "header",
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const api: GridApi = params.api;
          const thisRow: Record<string, GridCellValue> = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );

          return navigate("/details", {
            state: { data: thisRow, fireData: firebaseData },
          });
        };

        return (
          <Button
            variant="contained"
            style={{ maxHeight: "30px", minWidth: "30px", minHeight: "30px" }}
            onClick={onClick}
          >
            Select
          </Button>
        );
      },
    },
  ];

  return (
    <div className="parent bg-image">
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="relative"
          sx={{
            backgroundColor: "transparent",
            boxShadow: "none",
            margin: "0px",
          }}
        >
          <Toolbar>
            <IconButton
              color="primary"
              aria-label="go-back"
              size="small"
              style={{
                // position: "absolute",
                top: "10px",
                left: "10px",
                zIndex: "100",
              }}
            >
              <KeyboardBackspaceIcon /> <a href="/">GO BACK</a>
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
            ></Typography>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title={props.displayName}>
                <IconButton sx={{ p: 0, top: "10px", right: "3px" }}>
                  <Avatar
                    alt={props.displayName}
                    src="/static/images/avatar/2.jpg"
                  />
                </IconButton>
              </Tooltip>
              <Button
                variant="contained"
                size="small"
                sx={{
                  top: "10px",
                  left: "5px",
                }}
              >
                <a href="/" onClick={() => auth.signOut()}>
                  Sign Out
                </a>
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>

      <Container className="card-list">
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={4}>
            <Card sx={{
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              backdropFilter: "blur(10px)",
              borderRadius: "10px",
              boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
              backdropFilter: "blur(10px)",
              WebkitBackdropFilter: "blur(10px)",
              border: "1px solid rgba(255, 255, 255, 0.18)",
              maxWidth: "100%",
            }}>
              <BasicCard heading="Device Count" deviceCount={rows.length} 
              />
            </Card>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <Card sx={{
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              backdropFilter: "blur(10px)",
              borderRadius: "10px",
              boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
              backdropFilter: "blur(10px)",
              WebkitBackdropFilter: "blur(10px)",
              border: "1px solid rgba(255, 255, 255, 0.18)",
              maxWidth: "100%",
            }}>
              <BasicCard heading="Profile(s) Associated" deviceCount={0} />
            </Card>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <Card sx={{
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              backdropFilter: "blur(10px)",
              borderRadius: "10px",
              boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
              backdropFilter: "blur(10px)",
              WebkitBackdropFilter: "blur(10px)",
              border: "1px solid rgba(255, 255, 255, 0.18)",
              maxWidth: "100%",
            }}>
              <BasicCard heading="App(s) Distributed" deviceCount={0} />
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <motion.div
          initial={{ opacity: 0.5, x: 1100, y: -60 }}
          animate={{ opacity: 1, x: 1100, y: -60 }}
          transition={{ duration: 2.5 }}
        >
          <img
            src={computerSVG}
            className="computer"
            alt="computer"
            height="120px"
            width="120px"
            style={{
              transform: "rotate(20deg)",
            }}
          />
        </motion.div>

        <Table
          rows={rows}
          columns={columns}
          setSelectedRow={setSelectedRow}
          className="table"

        />
      </Container>

      <motion.div
        initial={{ opacity: 0, x: 80, y: -100 }}
        animate={{ opacity: 1, x: 80, y: -100 }}
        transition={{ duration: 2.5 }}
      >
        <img
          style={{
            transform: "rotate(-20deg)",
          }}
          src={computer2SVG}
          className="computer"
          alt="computer"
          height="175px"
          width="165px"
        />
      </motion.div>
    </div>
  );
}

export default Landing;
