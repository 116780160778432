import {React, useEffect, useState} from "react";

import DenseTable from "./fileListTable";
import Container from "@mui/material/Container";
import { motion } from "framer-motion";

import filelistSVG from "./filelist.svg";


import FirebaseData from "../../firebaseData";

function FileList(props) {

  const firebaseData = FirebaseData();
  const id=props.id;

  const [newData, setNewData] = useState();

  useEffect(() => {
    if (firebaseData) {
      setNewData(firebaseData[id]?.fileList);
    }
  }, [firebaseData, id]);

  const testData=[];
  for(const date in newData){
    for(const call in newData[date]){
      for(const key in newData[date][call]){
        testData.push(newData[date][call][key]);
      }
    }
  }



//   const fileList = props.fileList;

//   const data = [];

//   for (const date in fileList) {
//     for (const call in fileList[date]) {
//       for(const key in fileList[date][call]){
//         data.push(fileList[date][call][key]);
//       }
//     }
//   }
// console.log(fileList);

  return (
    <div>
      <Container>
      <motion.div
          initial={{ opacity: 0.5, x: 1080, y: -40 }}
          animate={{ opacity: 1, x: 1080, y: -40 }}
          transition={{ duration: 2.5 }}
        >
          <img
            src={filelistSVG}
            className="phone"
            alt="phone"
            height="130px"
            width="130px"
            style={{
              transform: "rotate(20deg)",
              position: "absolute",
            }}
          />
        </motion.div>
        <DenseTable data={testData} />
      </Container>
    </div>
  );
}

export default FileList;