import * as React from "react";

import { DataGrid} from "@mui/x-data-grid";

import "./style.css";

export default function DataTable(props) {


  return (
    <div style={{ height: 515, width: "100%" }}>
      <DataGrid
        style={{ letterSpacing: "1px" }}
        rows={props.rows}
        columns={props.columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        // getRowClassName={(params) => `super-app-theme--${params.row.status}`}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'blue' : 'black'
        }
        // experimentalFeatures={{ newEditingApi: true }}

        // checkboxSelection
        sx={{
          fontFamily: 'Montserrat',
          fontSize: '14px',     
          fontWeight: '550',
          color: "black",
          backgroundColor: "#FAF9F6",
        }}
      />
    </div>
  );
}
