import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Container from "@mui/material/Container";

import "./styles.css";

export default function DataTable(props) {
  const columns = [
    {
      field: "date",
      headerName: "Date",
      flex: 0.4,
      width: "auto",
      headerClassName: "header",
      type: "date",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "duration",
      headerName: "Duration",
      flex: 0.2,
      width: "auto",
      headerClassName: "header",
      type: "number",
      valueFormatter: (params) => `${params.value} min`,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "number",
      headerName: "Number",
      flex: 0.2,
      width: "auto",
      headerClassName: "header",
      type: "number",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "type",
      headerName: "Type",
      flex: 0.2,
      width: "auto",
      headerClassName: "header",
      type: "string",
      align: "left",
      headerAlign: "left",
    },
  ];

  // TODO : SORT BASED ON DATES
  // POSSIBLE SOLUTION: SLICE THE ARRAY AND THEN SORT IT AND MAKE DATE AND TIME SEPARATE COLUMNS

  const newRows = [];
  props.data.forEach((element) => {
    newRows.push(element);
  });

  return (
    <Container maxWidth="lg" >
      <div style={{ height: 630, width: "100%" }}>
        <DataGrid
          rows={newRows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowId={(row: any) => row.number + row.date}
          // getRowClassName={(params) => `super-app-theme--${params.row.status}`}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "blue" : "green"
          }
          sx={{
            "& .super-app-theme--pending": {
              backgroundColor: "#ff0000",
              color: "#fff",
            },
            "& .super-app-theme--completed": {
              backgroundColor: "#00ff00",
              color: "#fff",
            },
            "& .super-app-theme--cancelled": {
              backgroundColor: "#0000ff",
              color: "#fff",
            },
            fontFamily: "Montserrat",
            fontSize: "14px",
            fontWeight: "550",
            color: "black",
            backgroundColor: "#FAF9F6",
          }}

        />
      </div>
    </Container>
  );
}
