import {React, useEffect} from "react";
import { useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import {
    useJsApiLoader,
    GoogleMap,
} from "@react-google-maps/api";

function MapView(){
    const location = useLocation();
    // console.log(location.state.data);

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    })

    if(!isLoaded){
        return <Skeleton 
        sx={{
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',

        }} />
    }


    return(
        <div>
            <Box 
                sx={{
                    width: '100%',
                    height: '100%',
                    bgcolor: 'primary.main',
                    color: 'primary.contrastText',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <h1>Map View</h1>
            </Box>
            <Box>
                <GoogleMap
                    mapContainerStyle={{
                        width: '100%',
                        height: '100%',
                    }}
                    zoom={8}
                    center={{lat: 28.644800, lng: 77.216721}}
                >
                </GoogleMap>

            </Box>
        </div>
    )
}

export default MapView;